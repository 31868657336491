html {
  min-height: 100%;
  height: 100%;
}

body {
  min-height: 100%;
  height: 100%;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  position: relative;
  overflow-x: hidden;
  background-color: $white;
  color: $black;
  margin: 0;
  padding: 0;
}

*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

div,
header,
figure {
  margin: 0 auto;
  position: relative;

  &:after,
  &:before {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
  *zoom: 1;
}

a {
  color: inherit;
  text-decoration: none;
  @include hover_transition;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  line-height: 135%;
  margin: ($unit * 2) 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
}

address {
  font-style: normal;
}

input,
textarea {
  font-family: inherit;
  outline: none;
  padding: $unit $unit*2 $unit*1.5 $unit*2;
  border: 1px solid $lgreen;
  -webkit-font-smoothing: antialiased;
  display: block;
  background: $white;
  width: 100%;
  border-radius: 0;
}
::-webkit-input-placeholder {
  color: $black;
}
:-moz-placeholder {
  color: $black;
}
::-moz-placeholder {
  color: $black;
}
:-ms-input-placeholder {
  color: $black;
}

.btn,
button,
input[type="submit"] {
  border: none;
  cursor: pointer;
  text-decoration: none!important;
  border-radius: 0;
  padding: $unit*2 $unit $unit*2.5 $unit;
  @include hover_transition;
}

img {
  max-width: 100%;
}

iframe {
  width: 100%!important;
  max-width: 100%;
}
