// TOP LEVEL STUFF =============================

body {
  @include freight--reg;
}

header {
  padding: $unit*5 0;
  @media (max-width: $xs) {
    padding: $unit*2.5 0;
  }
  .logo {
    float: left;
    width: $unit*40;
    position: relative;
    z-index: 2;
    @media (max-width: $xs) {
      width: $unit*25;
    }
  }
  ul {
    list-style-type: none;
    float: right;
    text-align: right;
    @include acumin_wide--reg;
    color: $dgreen;
    margin-top: $unit*8;
    @media (max-width: $xs) {
      margin-top: $unit;
    }
    li {
      display: inline-block;
      margin-left: $unit*3;
      font-size: $unit*3;
      @media (max-width: $xs) {
        display: block;
        font-size: $unit*2.5;
        margin-left: 0;
        margin-bottom: $unit*.5;
      }
      a {
        color: inherit;
        &:hover, &:active {
          color: $lgreen;
        }
      }
      &.current-menu-item {
        @include acumin_wide--bold;
      }
    }
  }
}
.single-articles header ul li.menu-item-object-articles, .single-features header ul li.menu-item-object-features, header ul li.current-page-ancestor {
  @include acumin_wide--bold;
}

// CONTAINMENT =================================
.container {
  width: 90%;
  margin: 0 auto;
  max-width: 1400px;
}

.flex_wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

// CONTENT =====================================
.welcome_block {
  position: relative;
  top: -$unit;
  padding: $unit*10 10%;
  @include acumin_wide--reg;
  background: $dgreen;
  color: $white;
  @media (max-width: $med) {
    padding: $unit*6 7.5%;
  }
  @media (max-width: $xs) {
    padding: $unit*5 $unit*3;
    padding-bottom: $unit*8;
  }
  h1 {
    font-size: $unit*4;
    text-transform: uppercase;
    @include acumin_wide--bold;
    @media (max-width: $xs) {
      font-size: $unit*3.5;
      line-height: $unit*4;
    }
  }
  p {
    margin: $unit*5;
    font-size: $unit*3;
    line-height: $unit*5;
    @media (max-width: $xs) {
      margin: $unit*2.5 $unit*2;
      font-size: $unit*2.75;
      line-height: $unit*4;
    }
  }
  a {
    text-decoration: underline;
  }
}

// OUTLINED WRAPPER FOR ALL PAGES ==================
.content_wrapper {
  margin: 0 -2.5% $unit*5 -2.5%;
  width: 105%;
  border: 1px solid $lgreen;
  border-bottom: none;
  position: relative;
  z-index: 2;
  color: $dgreen;
  &--bottom-border-on {
    border-bottom: 1px solid $lgreen;
  }
  @media (max-width: $sml) {
    margin-top: $unit*4;
  }
  @media (max-width: $xs) {
    margin-top: $unit*5;
  }
}

// OUTLINED PAGE TITLES ===========================
.page_title {
  @include acumin_wide--bold;
  color: $dgreen;
  text-align: center;
  font-size: $unit*5;
  line-height: $unit*5.5;
  margin: -$unit*5 auto 0 auto;
  width: 66%;
  position: relative;
  z-index: 10;
  @media (max-width: $xs) {
    font-size: $unit*4;
    line-height: $unit*4.5;
    width: 90%;
    margin-top: -$unit*4;
  }
  span {
    display: inline-block;
    border: 1px solid $lgreen;
    background: $white;
    padding: $unit*2 $unit*10 $unit*3 $unit*10;
    padding-bottom: $unit*2.5;
    @media (max-width: $xs) {
      padding: $unit*1.5 $unit*3 $unit*2 $unit*4;
    }
    small {
      display: block;
      @include acumin_wide--reg;
      font-size: $unit*3;
    }
  }
}

// HERO IMAGES =====================================
.hero_image {
  width: 100%;
}
.single-features .hero_image {
  margin-top: -32px;
  margin-bottom: $unit*4;
  @media (max-width: $xs) {
    margin-top: -17px;
  }
}

// WYSIWYG CONTENT STYLING ========
.verbose_block {
  padding: 0 22.5%;
  text-align: justify;
  @media (max-width: $med) {
    padding: 0 17.5%;
  }
  @media (max-width: $sml) {
    padding: 0 15%;
  }
  @media (max-width: $xs) {
    padding: 0 $unit*6;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    @include acumin_wide--bold;
    color: $dgreen;
    line-height: 120%;
  }
  @media (max-width: $xs) {
    h1 {
      font-size: $unit*4;
    }
  }
  h2,
  h3,
  h4,
  h5 {
    text-align: left;
    margin-top: $unit*10;
  }
  p {
    font-size: $unit*3.5;
    line-height: $unit*5;
    @media (max-width: $xs) {
      font-size: $unit*3.25;
      line-height: $unit*5;
    }
    a {
      color: $lgreen;
      text-decoration: underline;
    }
  }
  ul, ol {
    font-size: $unit*3.5;
    line-height: $unit*5;
    li {
      margin-bottom: $unit*2;
      a {
        color: $lgreen;
        text-decoration: underline;
      }
    }
  }
  img {
    &.alignleft {
      float: left;
      margin: 0 $unit*5 $unit*5 0;
      max-width: 50%;
    }
    &.alignright {
      float: right;
      margin: 0 0 $unit*5 $unit*5;
      max-width: 50%;
    }
  }
  blockquote {
    margin: $unit*10 0 0 0;
    padding: $unit $unit*4;
    border-left: $unit solid $lgreen;
    p {
      margin: 0;
      @include acumin_wide--bold;
      font-size: $unit*4.5;
    }
  }
}
.single-articles .verbose_block h1 {
  width: 100%;
}

// FLEXIBLE CONTENT BLOCKS ========================
.additional_block {
  margin: $unit*8 auto;
  @media (max-width: $xs) {
    margin: $unit*6 auto;
  }
  &--gallery {
    padding: 0 15%;
    @media (max-width: $med) {
      padding: 0 10%;
    }
    @media (max-width: $med) {
      padding: 0 5%;
    }
    .gallery_img {
      width: 33.333333%;
      padding: $unit;
      align-self: flex-start;
      @media (max-width: $xs) {
        width: 49%;
      }
      a {
        display: block;
        overflow: hidden;
        position: relative;
        height: $unit*40;
        @media (max-width: $med) {
          height: $unit*30;
        }
        @media (max-width: $sml) {
          height: $unit*25;
        }
        img {
          @include centerer;
          width: 150%;
          max-width: 150%;
        }
      }
    }
  }
  &--wysiwyg {
    .col { width: 50%; float: left; padding-right: 50px; }
    img { margin: 50px 0 0 0; }
    a { text-decoration: underline; }
  }
  &--carousel {
    padding: $unit 12.5%;
    .carousel {
      list-style-type: none;
    }
    .bx-controls {
      position: absolute;
      width: 100%;
      top: 45%;
      left: 0;
      z-index: 100;
      @media (max-width: $xs) {
        top: 38%;
      }
      .bx-prev {
        position: absolute;
        left: 0;
        margin-left: -$unit*10;
        font-size: $unit*5;
        @media (max-width: $xs) {
          margin-left: -$unit*6.5;
        }
      }
      .bx-next {
        position: absolute;
        right: 0;
        margin-right: -$unit*10;
        font-size: $unit*5;
        @media (max-width: $xs) {
          margin-right: -$unit*6.5;
        }
      }
    }
  }
  &--video {
    padding: $unit 12.5%;
    @media (max-width: $xs) {
      padding: $unit 2.5%;
    }
  }
}

// MAGNIFIC MODAL GALLERY ===========================
.galleryPop {
  .mfp-bottom-bar,
  .mfp-close {
    display: block!important;
    @include acumin_wide--reg;
  }
  .mfp-arrow {
    color: $dgreen;
    &.mfp-arrow-right {
      &:before {
        border: none;
      }
      &:after {
        border-left: $unit*3 solid $dgreen;
        opacity: 1;
      }
    }
    &.mfp-arrow-left {
      &:before
      {
        border: none;
      }
      &:after {
        border-right: $unit*3 solid $dgreen;
        opacity: 1;
      }
    }
  }
}

// COLUMN LAYOUT LISTINGS =====================================
.listing_wrapper {
  padding: 0 2.5%;
}

.listing {
  color: $dgreen;
  display: inline-block;
  vertical-align: top;
  align-self: flex-start;
  h2 {
    @include acumin_wide--bold;
    margin: $unit*2 auto $unit auto;
    font-size: $unit*4;
    line-height: $unit*4.5;
    @include hover_transition;
  }
  p {
    font-size: $unit*3;
    line-height: $unit*4;
    margin-top: 0;
    @media (max-width: $xs) {
      font-size: $unit*3.25;
      line-height: $unit*5;
    }
    strong {
      @include acumin_wide--bold;
      @include hover_transition;
    }
  }
  img {
    width: 100%;
  }
  &--features {
    width: 32%;
    padding: 0 $unit*4;
    margin-top: $unit*6;
    margin-bottom: $unit*4;
    margin-left: 0;
    @media (max-width: $med) {
      width: 49%;
      padding: 0 $unit*2;
    }
    @media (max-width: $xs) {
      width: 100%;
    }
  }
  &--articles {
    width: 49%;
    padding: 0 $unit*4;
    margin-top: $unit*4;
    margin-bottom: $unit*4;
    text-align: left;
    @media (max-width: $xs) {
      width: 100%;
    }
    h2 {
      min-height: $unit*14;
      @media (max-width: $sml) {
        font-size: $unit*3;
        font-size: $unit*4;
      }
      small {
        display: block;
        @include acumin_wide--reg;
        font-size: $unit*3;
      }
    }
    .thumb {
      margin: $unit*3 auto $unit*2 auto;
      line-height: 0px;
      img {
        height: $unit*60;
        width: auto;
        background: grey;
        line-height: 0px;
      }
    }
  }
  &:hover strong, &:hover h2, &:active strong, &:active h2 {
    color: $lgreen;
  }
}

// STAGGERED CONTENT LAYOUT =======================
.staggered_content {
  padding: $unit*10 10% 0 10%;
  @media (max-width: $med) {
    padding: $unit*10 2.5% 0 2.5%;
  }
  .col {
    display: inline-block;
    vertical-align: top;
    align-self: flex-start;
    min-height: $unit*50;
    width: 50%;
    @media (max-width: $sml) {
      width: 100%;
      min-height: auto;
    }
    &--picture {
      padding: 0 $unit*5;
      @media (max-width: $sml) {
        padding: 0 22.5%;
      }
      @media (max-width: $xs) {
        padding: 0 12.5%;
      }
    }
    &--words {
      padding: $unit*10;
      @media (max-width: $med) {
        padding: $unit*5;
      }
      @media (max-width: $sml) {
        padding: $unit*3.5 15%;
      }
      @media (max-width: $xs) {
        padding: 0 5%;
      }
      h2 {
        @include acumin_wide--bold;
        @include hover_transition;
        margin: $unit*2 auto $unit*3 auto;
        font-size: $unit*5;
        line-height: $unit*4.5;
        @media (max-width: $xs) {
          font-size: $unit*4;
        }
      }
      p {
        font-size: $unit*3;
        line-height: $unit*4;
        margin-top: 0;
        @media (max-width: $xs) {
          font-size: $unit*3.25;
          line-height: $unit*5;
        }
        strong {
          @include acumin_wide--bold;
          @include hover_transition;
        }
      }
      img {
        width: 100%;
      }
    }
  }
  &:hover strong, &:hover h2, &:active strong, &:active h2 {
    color: $lgreen;
  }
  &:nth-of-type(odd) {
    flex-direction: row-reverse;
  }
}

// UTILITY SPACERS FOR LAYOUT =====================
.spacer {
  height: $unit;
  &--x2 {
    height: $unit*2;
  }
  &--x3 {
    height: $unit*3;
  }
  &--x4 {
    height: $unit*4;
  }
  &--x5 {
    height: $unit*5;
  }
}

// PAGINATION =====================================
.pagination_block {
  padding: $unit*6 10% $unit*15 10%;
  @media (max-width: $med) {
    padding: $unit*3.5 0 $unit*13 0;
  }
  ul {
    list-style-type: none;
    li {
      display: inline-block;
      width: 30%;
      text-align: center;
      @include acumin_wide--reg;
      text-transform: uppercase;
      color: $dgreen;
      font-size: $unit*3.5;
      @media (max-width: $sml) {
        font-size: $unit*3;
      }
      @media (max-width: $xs) {
        display: block;
        width: 100%;
        margin-bottom: $unit*2;
      }
      a {
        color: inherit;
        &:hover {
          color: $lgreen;
        }
      }
    }
  }
}

// FOOTER =====================================
footer {
  margin: -$unit*5 -2.5%;
  width: 105%;
  padding: $unit*4 0 0 0;
  border: 1px solid $lgreen;
  position: relative;
  z-index: 2;
  color: $dgreen;
  h5 {
    text-transform: uppercase;
    @include acumin_wide--bold;
    font-size: $unit*4;
    padding-bottom: $unit*4;
    border-bottom: 1px solid $lgreen;
    @media (max-width: $xs) {
      font-size: $unit*3.5;
      line-height: $unit*4;
      padding: 0 $unit*5;
      padding-bottom: $unit*3;
    }
  }
  .contact-details {
    width: 25%;
    border-right: 1px solid $lgreen;
    text-align: left;
    @include acumin_cond--reg;
    text-transform: uppercase;
    @media (max-width: $med) {
      width: 35%;
    }
    @media (max-width: $xs) {
      width: 100%;
      border-right: none;
    }
    ul {
      list-style-type: none;
      li {
        display: block;
        padding: $unit*3;
        border-bottom: 1px solid $lgreen;
        font-size: $unit*3.5;
        line-height: $unit*3.5;
        a:hover {
          color: $lgreen;
        }
      }
    }
  }
  .contact-form {
    width: 75%;
    padding: $unit*3;
    text-align: left;
    @media (max-width: $med) {
      width: 65%;
    }
    @media (max-width: $xs) {
      width: 100%;
    }
    p {
      margin-top: 0;
      font-size: $unit*3.5;
      strong {
        text-transform: uppercase;
        @include acumin_cond--semi;
      }
    }
    form {
      @include acumin_cond--reg;
      padding-bottom: $unit*12;
      p {
        margin: 0;
      }
      br {
        display: none;
      }
      input[type="text"], input[type="email"], input[type="tel"], textarea {
        font-size: $unit*3.5;
        background: lighten($lgreen, 40%);
        border: none;
        margin-bottom: $unit*2;
        @include acumin_cond--lite;
      }
      textarea {
        height: $unit*20;
      }
      ::-webkit-input-placeholder {
        @include acumin_cond--lite;
      }
      :-moz-placeholder {
        @include acumin_cond--lite;
      }
      ::-moz-placeholder {
        @include acumin_cond--lite;
      }
      :-ms-input-placeholder {
        @include acumin_cond--lite;
      }
      input[type="submit"] {
        background: $lgreen;
        color: $white;
        font-size: $unit*3.5;
        line-height: 100%;
        width: $unit*20;
        float: right;
        text-transform: uppercase;
        &:hover {
          background: $dgreen;
        }
      }
      .wpcf7-not-valid-tip {
        color: $dgreen;
        @include acumin_cond--semi;
        position: relative;
        top: -$unit*2;
      }
      div.wpcf7-response-output {
        margin: 0;
        padding: 0;
        border: none;
        &.wpcf7-mail-sent-ok {
          background: $dgreen;
          color: $white;
          margin-top: $unit*11;
          font-size: $unit*3;
          @include acumin_wide--reg;
          padding: $unit*2 $unit*3 $unit*3 $unit*3;
        }
        &.wpcf7-validation-errors {
          font-size: $unit*3.5;
          color: $dgreen;
          position: relative;
          top: -$unit*2;
          @include acumin_cond--semi;
        }
      }
    }
  }
}
body.home footer {
  padding-top: $unit*9;
  @media (max-width: $xs) {
    padding-top: $unit*7;
  }
}

.footer_slogan {
  background: $dgreen;
  color: $lgreen;
  padding: $unit*10 $unit*4 $unit*8 $unit*4;
  position: relative;
  z-index: 1;
  margin-bottom: $unit*10;
  p {
    text-transform: uppercase;
    @include acumin_wide--reg;
    font-size: $unit*3.5;
    line-height: $unit*4;
  }
}
