.component_grid_folio {
  margin-bottom: ($unit * 4);
  width: 50%;
  @media (min-width: $sml) {
    width: 33%;
  }

  p {
    font-size: ($unit * 2.75);
    line-height: ($unit * 3.25);
    margin-top: $unit;
  }

  &--big {
    width: 100%;
    margin-bottom: ($unit * 6);
    @media (min-width: $sml) {
      width: 50%;

      &:nth-of-type(odd) {
        padding-right: $unit;
      }

      &:nth-of-type(even) {
        padding-left: $unit;
      }
    }
    @media (min-width: $med) {
      width: 32%;
      margin-bottom: ($unit * 10);

      &:nth-of-type(odd) {
        padding-right: 0;
      }

      &:nth-of-type(even) {
        padding-left: 0;
      }

      &:nth-of-type(3n+2) {
        margin-left: 1.555555%;
        margin-right: 1.555555%;
      }
    }

    p {
      font-size: ($unit * 3.25);
      line-height: ($unit * 4.25);
    }
  }
}
