.component_rckt_carousel {
  list-style-type: none;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  li {
    display: block;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%; //border: ($unit * 4) solid $white;
  }
}

// BX Slider Overrides
.bx-viewport,
.bx-wrapper {
  height: 100%;
}
