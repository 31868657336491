// UNIT
$unit: 6px; // This is the unit by which everything – type and layout – units are measured.

// RESPONSIVE BREAKPOINTS
$xs: 414px;
$sml: 768px;
$med: 1024px;
$lrg: 1300px;

// TYPOGRAPHY STACK
@mixin freight--reg {
  font-family: "freight-text-pro", serif;
  font-style: normal;
  font-weight: 400;
}
@mixin freight--bold {
  font-family: "freight-text-pro", serif;
  font-style: normal;
  font-weight: 700;
}
@mixin acumin_wide--reg {
  font-family: "acumin-pro-wide", sans-serif;
  font-style: normal;
  font-weight: 400;
}
@mixin acumin_wide--bold {
  font-family: "acumin-pro-wide", sans-serif;
  font-style: normal;
  font-weight: 700;
}
@mixin acumin_cond--lite {
  font-family: "acumin-pro-condensed", sans-serif;
  font-style: normal;
  font-weight: 300;
}
@mixin acumin_cond--reg {
  font-family: "acumin-pro-condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
}
@mixin acumin_cond--semi {
  font-family: "acumin-pro-condensed", sans-serif;
  font-style: normal;
  font-weight: 600;
}

// COLOR PALETTE
$dgreen: #1F3239;
$lgreen: #558A9C;
$black: #111;
$white: white;
$lgrey: #ccc;

// OTHER
@mixin hover_transition {
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -ms-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
}

@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.show--lrg-scrns-only {
  display: none !important;
}
@media (min-width: $med) {
  .show--sml-scrns-only {
    display: none !important;
  }

  .show--lrg-scrns-only {
    display: inline-block !important;
  }
}
